import AbstractDataAttributeBehaviour from "./abstract-data-attribute-behaviour";

export default class EqualizeBehavior extends AbstractDataAttributeBehaviour {
    attrNames = {
        'container': 'data-equalize',
    };

    getContainerSelector() {
        return '[' + this.attrNames.container + ']';
    }

    equalizeItems(items){
        let height = 0;

        items.forEach( el => {
            //remove height so calcualted height is not based on pre-exsisitng instance of equalize
            el.style.removeProperty('height');
            let elementHeight = parseInt(el.offsetHeight);
            if( elementHeight > height ) height = elementHeight;
        } );

        if(height > 0) {
            items.forEach( el => {
                el.style.height = `${height}px`
            } );
        }
    }

    initContainer(container)
    {
        let querySelector = container.getAttribute( this.attrNames.container );
        let items = container.querySelectorAll(querySelector);
        this.equalizeItems(items);
        setTimeout(() => { this.equalizeItems(items) }, 1000);
        window.addEventListener('resize', () => { this.equalizeItems(items) } );
    }

}
