import AbstractDataAttributeBehaviour from "./abstract-data-attribute-behaviour";

export default class ToggleClassBehavior extends AbstractDataAttributeBehaviour {
    attrSelectors = {
        container: 'data-toggle-class',
        clear: 'data-toggle-clear',
        selector: 'data-toggle-class-name'
    };

    getContainerSelector()
    {
        return '[' + this.attrSelectors.container + ']';
    }

    initContainer(container)
    {
        let targetQuerySelector = container.getAttribute(this.attrSelectors.container);
        let targets = document.querySelectorAll(targetQuerySelector);

        let clearTargetQuerySelector = container.getAttribute(this.attrSelectors.clear);
        let clearTargets = document.querySelectorAll( clearTargetQuerySelector );

        container.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();

            targets.forEach( element => {
                let className = element.getAttribute(this.attrSelectors.selector);
                element.classList.toggle(className);
            });

            clearTargets.forEach( element => {
                let className = element.getAttribute(this.attrSelectors.selector);
                element.classList.remove(className);
            })
        });
    }
}