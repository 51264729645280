import AbstractHandler from "./abstractHandler";
import {validate} from "@babel/core/lib/config/validation/options";
import APIManager from "../../services/api-manager";
import RegexAssertion from "../assertions/RegexAssertion";

export default class NewAccountEmailHandler extends AbstractHandler {

    constructor(element, formValidator) {
        super(element, formValidator);
        this.apiManager = new APIManager
    }

    validate = () => {
        let valid = true
        let value = this.element.value;
        if(value === null || value === undefined) value = '';

        this.errors = [];

        if(value === '') {
            this.errors.push(this.message === undefined ? 'This is a required field' : this.message);
            this.validationState = this.STATUS_INVALID;
            this.updateDOM();
            return false;
        }

        let assertion = new RegexAssertion(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, 'gm');

        if(!assertion.test(value)) {
            this.validationState = this.STATUS_INVALID;
            this.errors.push('Please enter a valid email address');
            this.updateDOM();
            return false;
        }

        this.apiManager.abort();
        let signal = this.apiManager.createAbortSignal();
        let requestSettings = {method: "POST", signal: signal};
        let urlEncodedValue = encodeURIComponent(value);
        let request = new Request(`/api/validate/rol-email?value=${urlEncodedValue}`, requestSettings);
        this.apiManager.doFetch(request, response => {
            valid = response.valid;
            if(!valid) {
                this.errors.push(response.message);
                this.validationState = this.STATUS_INVALID;
            } else {
                this.validationState = this.STATUS_VALID;
            }

            this.updateDOM();
        });

        if(valid) this.validationState = this.STATUS_VALID;

       return valid;
    }
}