import React from 'react';
import FavouriteManager from '../../services/favourite-manager';

export default class ShortlistCount extends React.Component {

    constructor (props){
        super(props);
        this.favouriteManager = new FavouriteManager;
        this.state = { count: this.favouriteManager.getTotal() }
    }

    componentDidMount() {
        this.favouriteManager.onChange(this.onShortlistChange);
    }

    onShortlistChange = () => {
        let total = this.favouriteManager.getTotal();
        this.setState({ count: total });
    };

    render () {
        if (this.state.count < 1) return <></>;
        return (<span className="shortlist-count">{ this.state.count }</span>);
    }
}