import AbstractHandler from "./abstractHandler";
import {validate} from "@babel/core/lib/config/validation/options";

export default class DOBHandler extends AbstractHandler {

    constructor(element, formValidator) {
        super(element,formValidator);
    }

    validate = () => {
        let valid = true;
        let id = this.element.getAttribute('id');

        let dayField = document.getElementById(id + '_day');
        let monthField = document.getElementById(id + '_month');
        let yearField = document.getElementById(id + '_year');

        this.errors = [];

        if(dayField.value.length < 1) {
            this.errors.push('Please select day');
            valid = false;
            this.validationState = this.STATUS_INVALID;
        }

        if(monthField.value.length < 1) {
            this.errors.push('Please select month');
            valid = false;
            this.validationState = this.STATUS_INVALID;
        }

        if(yearField.value.length < 1) {
            this.errors.push('Please select year');
            valid = false;
            this.validationState = this.STATUS_INVALID;
        }

        let dob = new Date(parseInt(yearField.value) + 18, parseInt(monthField.value) -1, parseInt(dayField.value) -1);
        if(dob.getTime() >= new Date().setHours(0,0,0,0)) {
            this.errors.push('You must be at least 18 years old to register with ROL');
            valid = false;
            this.validationState = this.STATUS_INVALID;
        }

        if(valid) this.validationState = this.STATUS_VALID;
        this.updateDOM();

        return valid;
    }
}