import AbstractHandler from "./abstractHandler";

export default class AddressHandler extends AbstractHandler {

    validate = () => {
        let addressValid = true;
        this.errors = [];

        const validateAddress = new CustomEvent('reactAddressValidate', {
            detail : {'validate': (valid) =>  {
                addressValid = valid
            }}
        });

        document.dispatchEvent(validateAddress);
        return addressValid;
    }
}