import AbstractHandler from "./abstractHandler";

export default class RequiredHandler extends AbstractHandler {
    validate = () => {
        this.errors = [];
        this.validationState = this.STATUS_VALIDATING;
        let valid = false;

        this.inputs.forEach( input => {
            let value = input.value;

            if(input.tagName.toLocaleLowerCase() === 'input'){
                if(input.getAttribute('type') === 'radio' || input.getAttribute('type') === 'checkbox') {
                    value = input.checked ? true : null;
                }
            }

            if(value === null || value === undefined) value = '';

            if(value !== '') {
                this.validationState = this.STATUS_VALID;
                valid = true;
            }
        })

        if(valid) {
            this.validationState = this.STATUS_VALID;
        } else {
            this.validationState = this.STATUS_INVALID;
            this.errors.push(this.message === undefined ? 'This is a required field' : this.message);
        }
        this.updateDOM();

        return valid;
    }
}