import 'whatwg-fetch';
import CacheManager from './cache-manager';

import type {
    CruiseSearchType,
    CruiseSearchResponseType
} from "../flow/api_types";

export default class APIManager {

    constructor() {
        this.cache = new CacheManager;
        this.abortController = null;
        this.signal = null;
    }


    setAbortSignal(signal){
        this.signal = signal;

        return this;
    }

    setAbortController(controller) {
        this.abortController = controller;

        return this;
    }

    createAbortSignal() {
        const abortController = new AbortController();

        this.setAbortController(abortController);
        this.setAbortSignal(abortController.signal);

        return abortController.signal;
    }

    abort() {
        if (this.abortController && this.signal) {
            this.abortController.abort();
        }
    }

    getCruiseShipsByLineId(lineId, callback, errorCallback)
    {
        this.abort();
        const signal = this.createAbortSignal();

        let requestSettings = {
            method: "GET",
            signal: signal
        };

        let request = new Request(`/cruise-ships/${lineId}`, requestSettings);
        this.doFetch(request, callback, errorCallback);
    }

    getShortlistData(type, data, callback, errorCallback)
    {
        this.abort();
        const signal = this.createAbortSignal();

        let requestSettings = {
            method: "POST",
            body: data,
            signal: signal
        };

        let request = new Request(`/shortlist/${type}.json`, requestSettings);
        this.doFetch(request, callback, errorCallback);
    }

    getCruises(searchParameters: CruiseSearchType, callbackfn: Function, errorCallbackfn: Function): void
    {
        this.abort();
        const signal = this.createAbortSignal();

        let requestSettings = {
            method: "POST",
            body: JSON.stringify(searchParameters),
            signal: signal
        };

        let request = new Request('/api/cruise-search', requestSettings);
        this.doFetch(request, callbackfn, errorCallbackfn);
    }

    getPartial(partialUrl, callback, errorCallback, useCache = true)
    {
        let signal = this.signal;

        if (!this.cache.isItemExpired(partialUrl) && useCache) {
            callback(this.cache.getItem(partialUrl));
        } else {
            fetch(partialUrl, {signal})
                .then(response => {
                    if (!response.ok) {
                        errorCallback(response);
                        throw "There was a problem fetching partial: " + response.statusText; //trow error if html response code was not ok. Do this here to prevent cache an error response.
                    }

                    return response.text();
                })
                .then(html => {
                    if (useCache) {
                        this.cache.setItem(partialUrl, html);
                    }
                    callback(html);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log('Fetch aborted'); // @ignore
                    } else {
                        console.error('Uh oh, an error!', err); // @ignore
                    }
                });
        }
    }

    getAutocompleteChoices(endpoint, data, callback, errorCallback){
        let queryString = endpoint.split('?')[1];
        endpoint = `${endpoint}${queryString ? '&' : '?'}query=${encodeURIComponent(data)}`;

        let signal = this.signal;
        let requestSettings = {
            method: "GET",
            signal: signal
        };
        let request = new Request(endpoint, requestSettings);
        this.doFetch(request, callback, errorCallback);
    }

    getReviews(page, callback, errorCallback) {
        let endpoint = '/reviews.json?page=' + encodeURIComponent(page);
        let requestSettings = {method: "GET"};
        let request = new Request(endpoint, requestSettings);
        this.doFetch(request, callback, errorCallback);
    }

    doFetch(request, callback, errorCallback) {
        fetch(request)
            .then(response => {
                this.setAbortSignal(null);
                this.setAbortController(null);

                if (!response.ok) {
                    errorCallback(response);
                    throw "There was a problem fetching partial: " + response.statusText; //trow error if html response code was not ok. Do this here to prevent cache an error response.
                }

                return response.json();
            })
            .then(response => {
                callback(response);
            })
           .catch(error => {
               this.setAbortSignal(null);
               this.setAbortController(null);

               if(errorCallback) {
                   errorCallback(error);
               } else {
                   console.log(error); //@ignore
               }
           });
    }



    findAddress(addressId, callback, errorCallback)
    {
        this.abort();
        const signal = this.createAbortSignal();

        let requestSettings = {
            method: "POST",
            signal: signal,
        };

        const query = new URLSearchParams({
            Key: 'BP92-YE79-GR57-FJ76',
            Id: addressId,
            Field1Format: '{Longitude}',
            Field2Format: '{Latitude}',
            Field3Format: '{UDPRN}'
        });

        let request = new Request(`https://api.addressy.com/Capture/Interactive/Retrieve/v1.20/json3.ws?${query.toString()}`, requestSettings);
        this.doFetch(request, callback, errorCallback);
    }

    findAddressByPostcode(postcode, callback, errorCallback)
    {
        this.abort();
        const signal = this.createAbortSignal();

        let requestSettings = {
            method: "POST",
            signal: signal,
        };

        const query = new URLSearchParams({
            Key: 'BP92-YE79-GR57-FJ76',
            Text: postcode,
            Countries:'GBR',
            Language:'en-gb',
            Limit:10,
            Filters:postcode
        });

        let request = new Request(`https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?${query.toString()}`, requestSettings);
        this.doFetch(request, callback, errorCallback);
    }

    findAddressByContainer(id, callback, errorCallback)
    {
        this.abort();
        const signal = this.createAbortSignal();

        let requestSettings = {
            method: "POST",
            signal: signal,
        };

        const query = new URLSearchParams({
            Key: 'BP92-YE79-GR57-FJ76',
            Container:id,
            Countries:'GBR',
            Language:'en-gb',
            Limit:10,
        });

        let request = new Request(`https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?${query.toString()}`, requestSettings);
        this.doFetch(request, callback, errorCallback);
    }



    findAddressByGeocode(lat,lng, callback, errorCallback)
    {
        this.abort();
        const signal = this.createAbortSignal();

        let requestSettings = {
            method: "POST",
            signal: signal,
        };

        const query = new URLSearchParams({
            Key: 'BP92-YE79-GR57-FJ76',
            Latitude:lat,
            Longitude:lng,
            Countries:'GBR',
            Language:'en-gb',
            Limit:10,
        });

        let request = new Request(`https://api.addressy.com/Capture/Interactive/GeoLocation/v1/json3.ws?${query.toString()}`, requestSettings);
        this.doFetch(request, callback, errorCallback);
    }
    retrieveGeocodeAddress(id, callback, errorCallback)
    {
        this.abort();
        const signal = this.createAbortSignal();

        let requestSettings = {
            method: "POST",
            signal: signal,
        };

        const key = 'BP92-YE79-GR57-FJ76';
        let positionString = `Id=${id}`;
        let request = new Request(`https://api.addressy.com/Capture/Interactive/Retrieve/v1.2/json3.ws?Key=${key}&${positionString}`, requestSettings);
        this.doFetch(request, callback, errorCallback);
    }
}
