import FormValidator from "../validation/FormValidator";

class Form {
    HOOK_PRESUBMIT = 'preSubmit';
    HOOK_POSTSUBMIT = 'postSubmit';

    constructor(form)
    {
        this.form = form;
        this.formValidator = new FormValidator(form);

        this.hooks = {};
        this.hooks[this.HOOK_PRESUBMIT] = [];
        this.hooks[this.HOOK_POSTSUBMIT] = [];

        this.form.addEventListener('submit', this.onSubmit);
        this.form.addEventListener('invalid', this.onInvalid);
    };

    value = () => {
        let formData = new FormData(this.form);
        let object = {};
        formData.forEach((value, key) => {
            if(!Reflect.has(object, key)){
                object[key] = value;
                return;
            }
            if(!Array.isArray(object[key])){
                object[key] = [object[key]];
            }
            object[key].push(value);
        });

        return object;
    }

    registerHook = (hook, callback, priority = 0) => {
        this.hooks[hook].push(callback);
    }

    dispatchHook = (hook) => {
        this.hooks[hook].forEach( (callback) => callback(this) );
    }

    doRecaptcha = (callback) => {
        let recaptcha = document.getElementById(`${this.form.getAttribute('id')}_recaptchaToken`);
        if(recaptcha) {
            let publicKey = recaptcha.getAttribute('recaptcha');
            let action = recaptcha.getAttribute('action');

            grecaptcha.ready(() => {
                grecaptcha
                    .execute(publicKey, {action: action})
                    .then(token => {
                        recaptcha.value = token ;
                        callback();
                    });
            });
        } else {
            callback();
        }
    }

    submit = () => {
        this.form.submit();
    }

    onInvalid = (event) => {
        event.preventDefault();
        this.formValidator.validate((valid) => {});
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.dispatchHook('preSubmit');

        this.formValidator.validate().then((valid) => {
            this.dispatchHook('postSubmit');
            if(valid) this.doRecaptcha(this.submit);
        }).catch(console.log);
    }
}

export default class FormManager {


    constructor()
    {
        this.forms = {};
        this.init();
    };

    init() {
        let forms = document.querySelectorAll('form');
        forms.forEach( el => {
            this.addForm(el);
        });
    }

    addForm = (form) => {
        let id = form.getAttribute('id');
        this.forms[id] = new Form(form);
    }

    removeForm = (id) => {
        delete this.forms[id];
    }

    getForm = id => this.forms[id];
}
