import React from 'react';

export default class Toggle extends React.Component {

    constructor (props){
        super(props);
        this.state = {
            checked: this.props.checked,
            focused: false,
        };
    }

    componentWillReceiveProps(nextProps){
        if(this.state.checked !== nextProps.checked) this.setState({checked:nextProps.checked});
    }

    onKeyUp = (event: KeyboardEvent): void => {
        if (!this.state.focused) return;
        if(event.key === 'Enter') this.onToggle();
    };

    onToggle = (e) => {
        this.props.onToggle(!this.state.checked);
    };

    render() {
        return(
            <div className="toggle">
                <input className="toggle--input" id={this.props.id} type="checkbox" onChange={ () => {} } checked={this.state.checked}/>
                <label tabIndex="0" onKeyUp={ this.onKeyUp } onFocus={ () => this.setState({focused: true}) } onBlur={ () => this.setState({focused: false}) } className="toggle--container" htmlFor={this.props.id} onClick={this.onToggle}>
                    <span className="toggle--label">{this.props.onText||'on'}</span>
                    <span className="toggle--label">{this.props.offText||'off'}</span>
                </label>
            </div>
        )
    }
}