// @flow
import React from "react";
import APIManager from "../../services/api-manager";
import Analytics from '../../services/analytics';

import ErrorBoundary from "../error/error-boundary";
import { ordinalSuffix } from "../../utilities";



import type {
    CruiseSearchResponseType,
    CruiseSearchType
} from "../../flow/api_types";

type SearchFormProps = {
    searchParams: CruiseSearchType,
    config: Object,
    template: (formFields: Array<React$Element<*>>) => React$Element<*>,
    onChange?: (state: SearchFormState) => void,
    onWindowPopstate?: (searchParams: CruiseSearchType) => void,
    useAnalytics: boolean
}

type SearchFormState = {
    facets: Object,
    pager: Object,
    results: Object,
    searchParams: CruiseSearchType,
    http_query: string
}

export function getSortOptions(): Object {
    return { 'top picks':'Our Top Picks', 'departing-asc':'Departing (soonest first)', 'departing-desc':'Departing (distant first)', 'price-asc':'Price (low to high)', 'price-desc':'Price (high to low)' }
}

export function getYearOptions(start: number|null = null, count: number = 5 ): Object {
    if(!start) start = new Date().getFullYear();
    let years = Array.from({length: count}, (x,i) => [i + start, i + start]);
    let yearsObject = {};
    for(let i = 0; i< years.length; i++) {
        yearsObject[years[i][0]] = years[i][1]
    }
    return yearsObject;
}

export function getMonthOptions(): Object {
    return {'Jan':'January', 'Feb':'February', 'Mar':'March','Apr':'April', 'May':'May','Jun':'June', 'Jul':'July', 'Aug':'August', 'Sep':'September', 'Oct':'October', 'Nov':'November', 'Dec':'December'}
}

export function getDayOptions(month: number = 0, year: number = 0): Object {
    let days = Array.from({length: new Date(year, month, 0).getDate() }, (x,i) => [i +1, ordinalSuffix(i+1)] );
    let daysObject = {};
    for(let i = 0; i< days.length; i++) {
        daysObject[days[i][0]] = days[i][1]
    }
    return daysObject
}

export function getFlexibilityOptions(suffix: string|null = 'year'): Object {
    return { '3': '+/- 3 days', '7': '+/- 1 week', '14': '+/- 2 weeks', '31':'+/- 1 month' }
}

export function getDurationOptions(): Object {
    return { '1 - 5 nights': '1 - 5 nights', '6 - 9 nights': '6 - 9 nights', '10 - 15 nights': '10 - 15 nights', '16 - 20 nights':'16 - 20 nights', '21+ nights':'21+ nights'}
}

export class SearchForm extends React.Component<SearchFormProps, SearchFormState> {

    constructor(props: SearchFormProps) {
        super(props);

        this.APIManager = new APIManager();
        this.Analytics = new Analytics();

        let formatedSearchParams = this.formatSearchParams(this.props.searchParams);

        this.state = {
            facets: {},
            pager: {},
            results: {},
            searchParams: formatedSearchParams,
            http_query: ''
        };
    }

    componentDidMount = (): void => {
        this.handleChange(false);
        window.onpopstate = this.onWindowPopstate;
    };

    refreshSearch = newParams => {
        this.setState({ searchParams: {
            ...this.state.searchParams,
            ...this.formatSearchParams(newParams)
        }}, () => {
            this.handleChange(true);
        });
    }

    formatSearchParams  = (searchParams: CruiseSearchType):SearchFormProps => {
        if(!searchParams) return {};
        let newProps = {
            ...searchParams,
        };

        if(searchParams.departsUk) newProps.departsUk = !!searchParams.departsUk;
        if(searchParams.lastMinute) newProps.lastMinute = !!searchParams.lastMinute;
        if(searchParams.maxNights) newProps.maxNights = parseInt(searchParams.maxNights);
        if(searchParams.maxValue) newProps.maxValue =  parseInt(searchParams.maxValue);
        if(searchParams.maxToValue) newProps.maxToValue =  parseInt(searchParams.maxToValue);
        if(searchParams.maxWasValue) newProps.maxWasValue =  parseInt(searchParams.maxWasValue);
        if(searchParams.minNights) newProps.minNights = parseInt(searchParams.minNights);
        if(searchParams.minValue) newProps.minValue = parseInt(searchParams.minValue);
        if(searchParams.minToValue) newProps.minToValue = parseInt(searchParams.minToValue);
        if(searchParams.minWasValue) newProps.minWasPrice = parseInt(searchParams.minWasValue);
        if(searchParams.page) newProps.page = parseInt(searchParams.page);
        if(searchParams.perPage) newProps.perPage = parseInt(searchParams.perPage);
        if(searchParams.solo) newProps.solo = !!searchParams.solo;
        if(searchParams.worldCruise) newProps.worldCruise = !!searchParams.worldCruise;

        return newProps;
    };


    onWindowPopstate = (event: Object):void => {
        let searchParams = this.formatSearchParams(event.state ? event.state : this.props.searchParams);
        if(this.props.onWindowPopstate) this.props.onWindowPopstate(searchParams);
        this.setState({searchParams: searchParams}, () => this.handleChange(false));
    };

    resetSearch = (): void => {
        this.setState({searchParams: {}}, () => this.handleChange(true));
    };

    resetProperty = (propertyPath: String): void => {
        let curSearchParams = this.state.searchParams;
        if (curSearchParams[propertyPath]) delete curSearchParams[propertyPath];
        this.setState({searchParams: curSearchParams}, () => this.handleChange(true));
    };

    onFieldRemoveValue = (propertyPath: String, value:string):void => {
        let property = this.state.searchParams[propertyPath];

        if(typeof property === 'object') {
            let curSelected = this.state.searchParams[propertyPath] ? [...property] : [];
            let index = this.state.searchParams[propertyPath].findIndex(sel => sel === value);
            curSelected.splice(index, 1);
            this.onFieldUpdate(propertyPath, curSelected);
        } else {
            this.onFieldUpdate(propertyPath, '');
        }
    };

    onFieldUpdate = (propertyPath: String, data: any):void => {
        let page = propertyPath === 'page' ? data : 1;
        this.setState({searchParams: {...this.state.searchParams, [propertyPath]:data, page: page}}, () => this.handleChange(true));
    };

    handleChange = (pushPopState: boolean): void => {
        this.APIManager.getCruises(
            this.state.searchParams,
            response => this.onChange(response, pushPopState),
            error => console.error(error)
        );
    };

    updateDataLayer = (clientId) => {
        //@flow ignore
        if(dataLayer) {
            let now = new Date();
            dataLayer.push({
                timestamp: now.getDate() + '-' + (now.getMonth() + 1) + '-' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds() + ':' + now.getMilliseconds(),
                event:'search',
                session: this.randomId,
                user: clientId,
                ...this.state.searchParams
            });
        }

    };

    onChange = (response: CruiseSearchResponseType, pushPopState: boolean): void => {
        this.setState({...response}, () => {
            if(this.props.onChange) this.props.onChange(this.state);
            if(pushPopState) history.pushState(this.state.searchParams, 'search', `${window.location.pathname}?${this.state.http_query}${window.location.hash}`);
            if(this.props.useAnalytics) this.Analytics.loadClientId(this.updateDataLayer );
        });
    };

    getComponents = (): Object => {
        let components = {};
        Object.keys(this.props.config).forEach( propertyPath => components[propertyPath] = this.createComponent(propertyPath, this) );
        return components;
    };

    createComponent = (propertyPath: string, form: Object): React$Element<ErrorBoundary> => {
        let config = this.props.config[propertyPath];
        return  (<ErrorBoundary> { config.renderMethod(propertyPath, form, config) } </ErrorBoundary>);
    };

    render() {
        return this.props.template(this.getComponents());
    }
}



