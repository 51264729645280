export default class CookieManager {

    constructor() {

    }

    setCookie(name, value, expires)
    {
        let cookie = name + "=" + escape(value) + "; path=/" + ((expires == null) ? "" : "; expires=" + expires.toGMTString());
        document.cookie = cookie;
    }

    getCookie(name)
    {
        var dc = document.cookie;
        var cname = name + "=";
        var begin = null, end = null;
        if (dc.length > 0) {
            begin = dc.indexOf(cname);
            if (begin != -1) {
                begin += cname.length;
                end = dc.indexOf(";", begin);
                if (end == -1) end = dc.length;

                return decodeURI(dc.substring(begin, end));
            }
        }

        return null;
    }
}