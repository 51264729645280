import AbstractHandler from "./abstractHandler";
import APIManager from "../../services/api-manager";

export default class RAFHandler extends AbstractHandler {

    constructor(element, formValidator) {
        super(element, formValidator);
        this.apiManager = new APIManager
    }
    validate = () => {
        let rafCode = this.element.value;
        this.errors = [];
        this.validationState = this.STATUS_VALIDATING;

        if(rafCode.length < 1) {
            this.validationState = this.STATUS_VALID;
            this.updateDOM();
            return true;
        }

        let email = document.getElementById('registration_email_first').value;
        if(email.length < 1) {
            this.validationState = this.STATUS_INVALID;
            this.errors.push('Please enter an email in section 1');
            this.updateDOM();
            return false;
        }

        let valid = true
        const signal = this.apiManager.createAbortSignal();
        let requestSettings = {method: "POST", signal: signal};

        let request = new Request(`/api/validate/rol-raf?value=${rafCode}&email=${email}`, requestSettings);
        this.apiManager.doFetch(request, response => {
            valid = response.valid;
            if(!valid) {
                this.errors.push(response.message);
                this.validationState = this.STATUS_INVALID;
            }

            if(valid) this.validationState = this.STATUS_VALID;
            this.updateDOM();
        });

        if(valid) this.validationState = this.STATUS_VALID;
        this.updateDOM();
        return valid;
    }
}