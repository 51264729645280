import CommunicationPreferencesHandler from "./handlers/communicationPreferencesHandler";
import DuplicateEmailHandler from "./handlers/duplicateEmailHandler";
import PasswordHandler from "./handlers/passwordHandler";
import EmailHandler from "./handlers/emailHandler";
import RequiredHandler from "./handlers/requiredHandler";
import FirstNameHandler from "./handlers/firstNameHandler";
import LastNameHandler from "./handlers/lastNameHandler";
import AddressHandler from "./handlers/addressHandler";
import DOBHandler from "./handlers/DOBHandler";
import RAFHandler from "./handlers/RAFHandler";
import PhoneNumberHandler from "./handlers/phoneNumberHandler";
import NewAccountEmailHandler from "./handlers/newAccountEmailHandler";

if (typeof app === 'undefined') var app = {};

export default class FormValidator {
    STATUS_UNVALIDATED = 0;
    STATUS_VALIDATING = 1;
    STATUS_INVALID = 5;
    STATUS_WARNING = 8;
    STATUS_VALID = 10;

    settings = {
        validate_query: '[data-live-validate]',
        validator_type_attribute: 'data-live-validate',
    }

    handlers = {
        'communicationPreferences': CommunicationPreferencesHandler,
        'duplicateEmailConfirmation': DuplicateEmailHandler,
        'password':PasswordHandler,
        'newAccountEmail': NewAccountEmailHandler,
        'email': EmailHandler,
        'required': RequiredHandler,
        'forename': FirstNameHandler,
        'surname': LastNameHandler,
        'address': AddressHandler,
        'dob': DOBHandler,
        'raf': RAFHandler,
        'phoneNumber': PhoneNumberHandler
    };

    fields = [];

    validationState = this.STATUS_UNVALIDATED;

    constructor(element, settings = {}) {
        if (element.formValidatorInstance) {
            return element.formValidatorInstance;
        }

        element.setAttribute('novalidate', true); //disables html validation if js validation is being used.
        this.settings = { ...this.settings, ...settings };
        let fieldsToValidate = element.querySelectorAll(this.settings.validate_query);

        fieldsToValidate.forEach((field) => {
            this.createValidator(field);
        });
        element.formValidatorInstance = this;
    }

    createValidator = (element) => {
        let handlerName = element.getAttribute(this.settings.validator_type_attribute);

        if(handlerName){
            let handler = this.handlers[handlerName];
            if(handler) this.fields.push(new handler(element, this));
        }
    }


    validate = () => {
        this.validationState = this.STATUS_VALIDATING;
        let validationCallbacks = [];

        this.fields.forEach((field) => {
            validationCallbacks.push(new Promise( (accept, reject) => {
                let valid = field.validate();
                if(valid) accept(valid);
                reject(valid);
            }));
        });

        return Promise.all(validationCallbacks).then(values => {
            this.validationState = this.STATUS_VALID;
            return true;
        }).catch( error => {
            this.validationState = this.STATUS_INVALID;
            return false;
        });
    }

    isValid = () => this.validationState === this.STATUS_VALID;

    reset = () => {
        this.fields.forEach(field => field.reset());
    }
};