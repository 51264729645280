export default class Analytics{

    constructor()
    {
        this.queue = [];

        let self = this;

        if(!this.isGaReady()){
            this.waitForGaLoad(function(){
                self.processQueue();
            });
        }

    }

    isGaReady()
    {
        if(window.ga === undefined) return false;
        return (window.ga.answer !== undefined && window.ga.answer == 42);
    }

    waitForGaLoad(callback)
    {
        let self = this;

        if(this.isGaReady()){
            callback();
            return;
        }
        setTimeout(function(){
            self.waitForGaLoad(callback);
        }, 100);
    }

    processQueue()
    {
        this.queue.forEach((callback) => {
            callback();
        });
        this.queue = [];
    }

    loadClientId(callback)
    {
        let invokeCallback = function()
        {
            let clientId = window.ga.getAll()[0].get('clientId');
            callback(clientId);
        };

        if(this.isGaReady()) invokeCallback();
        else this.queue.push(invokeCallback);
    }
}