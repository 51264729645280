// @flow

import React from "react";
import Scrollable from "./scrollable";
import Checkbox from "./checkbox";
import Pill from "./pill";
import APIManager from "../../services/api-manager";

type AutoCompleteProps = {
    value: Object,
    onUpdate: (value: Object) => void,
    placeholder: string,
    label: string,
    defaultOptions: Object,
    defaultLabel: string,
    searchLabel: string,
    endpoint: string,
    apiManager?: APIManager | null
}

type AutoCompleteState = {
    value: Object,
    focus: number,
    options: Object,
    searchString: string
}

export default class AutoComplete extends React.Component<AutoCompleteProps, AutoCompleteState> {

    static defaultProps = {
        apiManager: null,
        editPayloadCallback: val => val
    }

    constructor(props: AutoCompleteProps) {
        super(props);

        this.APIManager = this.props.apiManager;
        if(!this.APIManager) this.APIManager = new APIManager();

        this.state = {
            value: this.props.value && typeof this.props.value !== "undefined" ? this.props.value : [],
            focus: 0,
            options: {},
            searchString: ''
        }
    }

    componentWillReceiveProps(nextProps: AutoCompleteProps){
        if(this.state.value !== nextProps.value) this.setState({value: nextProps.value && typeof nextProps.value !== "undefined" ? nextProps.value : []});
    }


    getValueIndex = (item:string):number => {
        return this.state.value.findIndex(sel => sel === item);
    };

    getKeyPressCallBack = (e:KeyboardEvent): Function => {
        let keyUpCallbacks =  {
            '13': this.onKeyEnter,
            '38': this.onKeyUp,
            '40': this.onKeyDown
        };

        if(window.event) {// IE
            if(keyUpCallbacks[e.keyCode]) return keyUpCallbacks[e.keyCode];
        } else if(e.which){ // Netscape/Firefox/Opera
            if(keyUpCallbacks[e.which]) return keyUpCallbacks[e.which];
        }

        return null;
    };

    onKeyUpEvent = (e: KeyboardEvent):void => {
        if (e.key === 'Control') return false;

        let keyUpMethod = this.getKeyPressCallBack(e);
        let target = e.target;
        if (target instanceof HTMLInputElement) return keyUpMethod ? keyUpMethod() : this.setState( {searchString: target.value} ,this.onChange(target.value));
    };

    onKeyUp = ():void => {
        let oldFocus = this.state.focus;
        let newFocus = oldFocus >= 0  ? oldFocus-1 : oldFocus;
        this.setState({ focus: newFocus });
    };

    onKeyDown = ():void => {
        let optionsLength = this.state.options ? this.state.options.length||5 : 5;
        let oldFocus = this.state.focus;
        let newFocus = oldFocus < (optionsLength - 1) ? oldFocus+1 : oldFocus;
        this.setState({ focus: newFocus })
    };

    onKeyEnter = ():void => {
        let value = null;
        if(this.state.options && this.state.options[this.state.focus]) value = this.state.options[this.state.focus];
        this.onSelect(value);
    };

    onSelect = (value:any):void => {
        let curSelected = [...this.state.value];
        let index = this.getValueIndex(value);
        index >= 0 ? curSelected.splice(index, 1) : curSelected.push(value);
        this.props.onUpdate(curSelected);
    };

    onRemove = (value:string):void => {
        let curSelected = [...this.state.value];
        let index = this.getValueIndex(value);
        curSelected.splice(index, 1);
        this.props.onUpdate(curSelected);
    };

    onChange = (value:string):void => {
        this.APIManager.getAutocompleteChoices (
            this.props.endpoint,
            this.props.editPayloadCallback(value),
            response => this.setState({ options: response, focus: 0 }),
            error => console.error(error)
        );
    };

    onPasteEvent = e => {
        let curSelected = [...this.state.value];

        e.clipboardData.getData('Text').split(',').map(val => {
            let index = this.getValueIndex(val);
            index < 0 && curSelected.push(val);
        });

        this.props.onUpdate(curSelected);
    }

    renderOption = (value:string):React$Element<*> => {
        return (
            <li className='facet--item active'>
                <Checkbox label={value} checked={ this.state.value.includes(value) } onChange={ () => {  this.onSelect( value ) } }/>
            </li>
        );
    };

    renderDefaultOptions():React$Element<*> {
        if(!this.props.defaultOptions || !this.props.defaultLabel) return (<></>);
        return (
            <>
                <h6 className="font-family-body font-style-bold">{ this.props.defaultLabel } </h6>
                <ul className="facet--list">
                    { Object.keys(this.props.defaultOptions).map( (key) => this.renderOption(this.props.defaultOptions[key]) )}
                </ul>
            </>
        )
    }

    renderOptions():React$Element<*>{
        return (
            <>
                <h6 className="font-family-body font-style-bold">{ this.props.searchLabel  }</h6>
                <Scrollable className="facet--simple-bar" >
                    <ul className="facet--list">
                        { Object.keys(this.state.options).map((key) => this.renderOption(this.state.options[key]) )}
                    </ul>
                </Scrollable>
            </>
        )
    }

    renderSelectedTag = (i:string):React$Element<*> => <Pill label={ this.state.value[i] } onRemove={ () => this.onRemove(this.state.value[i]  ) } />;

    render() {
        return (
            <div className={"facet " + this.props.className } >
                <div className="facet--header">
                    <h6 className="facet--heading" >{ this.props.label}</h6>
                    <span className="facet--clear" onClick={ () =>  this.props.onUpdate([]) }>Clear</span>
                </div>
                { this.state.value && this.state.value.length > 0 && <Scrollable className="facet--simple-bar-horizontal" scrollY={false} scrollX={true} >
                    <div className="facet--tag-container">
                        { this.state.value && Object.keys(this.state.value).map(this.renderSelectedTag )}
                    </div>
                </Scrollable> }
                <input className="search-input" onPaste={this.onPasteEvent} onKeyUp={ this.onKeyUpEvent } type={'text'} placeholder={ this.props.placeholder }/>
                {this.props.renderAdditionalContent && this.props.renderAdditionalContent()}
                { this.state.searchString.length > 0 ? this.renderOptions() : this.renderDefaultOptions() }
            </div>
        )
    }

}

