export default class FavouriteManager {

    constructor()
    {
        this.storage = localStorage;
        this.KEY_NAME = 'favourites';
        this.defaultStorage = {
            cruise: [],
            line: [],
            location: []
        };

        this.listeners = [];

        window.addEventListener('favourite', (e) => { this.onStorageChange(e.detail); });
        window.addEventListener('storage', (e) => { this.onStorageChange(e); });
    };

    getTotal = (type) => {
        let favourites = this.loadFromStorage();

        if (type) return favourites[type].length;
        return favourites.cruise.length + favourites.line.length + favourites.location.length;
    };

    getFavourites = (type) => {
        let favourites = this.loadFromStorage();
        if (type) return favourites[type];
        return favourites;
    };

    getFavouritesGroup = (type, subtype) => {
        let items = this.getFavourites(type);

        let filtered = items.filter((item) => {
            return (subtype === item.split('_')[0]);
        });

        return filtered.map((item) => {
            return item.split('_')[1];
        })

    };

    getFavouritesAsString = (type, subtype) => {
        if(subtype) return this.getFavouritesGroup(type,subtype).join(',');
        return this.getFavourites(type).join(',');
    };

    isFavourite = (type, itemId) => {
        let favourites = this.loadFromStorage();
        if(!favourites) return false;
        if(type && !favourites[type]) return false;
        if(favourites[type].length < 1) return false;
        return favourites[type].includes(itemId);
    };

    addFavourite = (type, itemId) => {
        if (!this.isFavourite(type, itemId)) {
            let favourites = this.loadFromStorage();
            if (!favourites[type]) favourites[type] = [];
            favourites[type].push(itemId);

            this.saveToStorage(favourites);
        }
    };

    removeFavourite = (type, itemId) => {
        let favourites = this.loadFromStorage(type);
        if(!favourites[type]) return;
        let index = favourites[type].findIndex((item) => item === itemId);
        if (index !== -1) {
            favourites[type].splice(index, 1);
        }

        this.saveToStorage(favourites);
    };

    onChange = (callback) => {
        this.listeners.push(callback);
    };


    onStorageChange = (data) => {
        this.listeners.forEach((listener) => {
            listener(data);
        });
    };

    createChangeEvent = (oldValue, newValue) => {
        let details = {
            key:this.KEY_NAME,
            oldValue:oldValue,
            newValue:newValue,
            path:null,
            storageArea: this.storage
        };

        var favouriteEvent = new CustomEvent("favourite", {detail:details});
        window.dispatchEvent(favouriteEvent);
    };

    loadFromStorage = () => {
        let favourites = this.storage.getItem(this.KEY_NAME);
        if(!favourites) return this.defaultStorage;

        return JSON.parse(favourites);
    };

    saveToStorage = (data) => {
        try {
            let oldData = this.loadFromStorage();
            this.storage.setItem(this.KEY_NAME, JSON.stringify(data));
            this.createChangeEvent(oldData, data);
        } catch (error) {
            console.log('cannot add to favourites:'+ error); // @ignore
        }
    };

    clearFromStorage = () => {
        let oldData = this.loadFromStorage();
        this.storage.removeItem(this.KEY_NAME);
        this.createChangeEvent(oldData, null);
    };
}
