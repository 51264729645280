import React from "react";

export default class Search extends React.Component {
    constructor(props) {
        super(props);
    }

    onChange = e => this.props.onComplete(this.doSearch(e.target.value));

    getRegexList = (text) => {
        return [new RegExp(`${text.toLowerCase()}`)];
    };

    doSearch = (text) => {
        let regExList = this.getRegexList(text);
        return Object.keys(this.props.items).filter( (item) => {
            if (regExList.some(rx => rx.test(item.toLowerCase()))) return item;
        });
    };

    render() {
        return (
            <input className="search-input" onChange={ this.onChange } placeholder={ this.props.placeholder } type={'text'}/>
        )
    }

}

