import AbstractHandler from "./abstractHandler";
import {validate} from "@babel/core/lib/config/validation/options";
import APIManager from "../../services/api-manager";
import RegexAssertion from "../assertions/RegexAssertion";

export default class EmailHandler extends AbstractHandler {

    constructor(element, formValidator) {
        super(element, formValidator);
        this.apiManager = new APIManager
    }

    validate = () => {
        let valid = true
        let value = this.element.value;
        if(value === null || value === undefined) value = '';
        this.errors = [];

        this.validationState = this.STATUS_VALIDATING;
        this.updateDOM();

        if(value === '') {
            this.errors.push(this.message === undefined ? 'This is a required field' : this.message);
            this.validationState = this.STATUS_INVALID;
            valid = false;
        }

        let assertion = new RegexAssertion(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, 'gm');
        if(!assertion.test(value)) {
            valid = false;
            this.validationState = this.STATUS_INVALID;
            this.errors.push('Please enter a valid email address');
        }

        if(valid) this.validationState = this.STATUS_VALID;
        this.updateDOM();

        return valid;
    }
}