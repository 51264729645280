export function normaliseImagePath(path)
{
    if(!path) return null;
    path = '' + path; // Cast path as a string
    if(path.match(/^https?:\/\//)) return path; // Return unchanged if path begins with http:// or https://
    if(path.slice(0,1) !== '/') return '/' + path; // Return with leading slash added if leading slash is missing
    return path; // Return unchanged by default
}

export function ordinalSuffix(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}