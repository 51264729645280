//@flow

import React from 'react';

type ScrollableProps = {
    className: string,
    children: any,
    scrollY: boolean,
    scrollX: boolean
}

type ScrollableState = {
    children: any,
    scrollY: boolean,
    scrollX: boolean
}

export default class Scrollable extends React.Component<ScrollableProps, ScrollableState> {

    static defaultProps = {
        scrollY: true,
        scrollX: false
    };

    constructor(props: ScrollableProps) {
        super(props);
        this.state = {
            children: this.props.children,
            scrollY: this.props.scrollY,
            scrollX: this.props.scrollX
        }
    }

    componentWillReceiveProps(nextProps: ScrollableProps, nextContext: ScrollableState): void {
        this.setState({
            children: nextProps.children
        });
    }

    render(){
        let scrollClassNames = 'scrollable ';
        if(this.state.scrollY) scrollClassNames += 'scroll-y ';
        if(this.state.scrollX) scrollClassNames += 'scroll-x ';

        return (
            <div className={ scrollClassNames + this.props.className } >
                { this.state.children }
            </div>
        )
    }
};