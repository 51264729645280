(function(){
    function createCookie(name, value, days) {
        var expires;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            expires = "; expires="+date.toGMTString();
        }
        else {
            expires = "";
        }
        document.cookie = name+"="+value+expires+"; path=/";
    }

    let element = document.querySelector('[data-upper-message-bar-info]');

    if(element){
        let name = element.getAttribute('data-upper-message-bar-info');
        let cookieName = 'notification-banner-' + name;

        element.querySelector('[data-close-message-bar]').addEventListener('click', function(e){
            e.preventDefault();
            createCookie(cookieName, "1", 1);
            element.classList.add('hide');
        });
    }

})();











