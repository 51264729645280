import AbstractHandler from "./abstractHandler";
import {validate} from "@babel/core/lib/config/validation/options";

export default class DuplicateEmailHandler extends AbstractHandler {
    validate = () => {
        let valid = true
        let id = this.element.getAttribute('id');
        let first = document.querySelector('#' + id.replace('_confirm','_'));

        this.errors = [];

        if(first.getAttribute('id') === id) first = document.querySelector('#' + id.replace('_second','_first'));

        if(this.element.getAttribute('data-duplicate-name')){
            first = document.querySelector('[data-duplicate-name="' + this.element.getAttribute('data-duplicate-name').replace('_second','_first') + '"]');
        }

        if(first.value.length < 1) {
            this.errors.push('First input is empty.');
            this.validationState = this.STATUS_INVALID;
            valid = false;
        }

        if(this.element.value !== first.value) {
            this.errors.push('Please ensure email address matches');
            this.validationState = this.STATUS_INVALID;
            valid =  false;
        }

        if(valid) this.validationState = this.STATUS_VALID;
        this.updateDOM();

        return valid;
    }
}