import config from 'js-yaml-loader!../../../../config/breakpoints.yaml';

export default class BreakpointManager {
    constructor()
    {
        let self = this;
        this.listeners = [];
        this.resizeTimeout = null;
        this.mediaQueryAlias = this.generateMediaQueryAliases();
        this.customQueryAlias = this.generateCustomQueryAliases();
        this.breakpoints = this.getActiveBreakPoint();

        window.addEventListener('resize', () => {
            self.onResize();
        });
    }

    onResize()
    {
        let self = this;
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            this.breakpoints = self.getActiveBreakPoint();

            this.listeners.forEach((listener) => {
                listener(this.breakpoints);
            });
        }, 50);
    }

    onChange(callback)
    {
        this.listeners.push(callback);
    }

    getActiveBreakPoint(){
        let keys = [];
        let aliases = this.mediaQueryAlias;
        Object.keys(aliases).forEach((key) => {
            let mediaQuery = aliases[key];
            let mediaQueryMatch = window.matchMedia(mediaQuery);
            if(!mediaQueryMatch.matches) return;
            keys.push(key);
        });

        Object.keys(this.customQueryAlias).forEach((key) => {
            let mediaQuery = this.customQueryAlias[key];
            let mediaQueryMatch = window.matchMedia(mediaQuery);
            if(!mediaQueryMatch.matches) return;
            keys.push(key);
        });

        return keys;
    }

    /**
     * @return {Object}
     */
    getBreakpoints(){
        return config.breakpoints;
    }

    getBreakPointWidth(alias){
        let breakpoints = this.getBreakpoints();
        return breakpoints[alias].size;
    }

    getBreakPointAlias(width){
        let breakpoints = this.getBreakpoints();
        Object.keys(breakpoints).forEach((key) => {
            let nextKey = parseInt(key) + 1;
            let breakpoint = breakpoints[key];
            let minWidth = breakpoint.size;
            let maxWidth = breakpoints[nextKey] ? breakpoints[nextKey].size - 1 : null;

            if(minWidth) mediaQueries[breakpoint.alias + '_up'] = '(min-width: ' + minWidth + 'px)';
            if(maxWidth) mediaQueries[breakpoint.alias + '_down'] = '(max-width: ' + maxWidth + 'px)';
            if(width < maxWidth && width > maxWidth) return breakpoint.size;
        });
    }

    generateMediaQueryAliases(){
        let breakpoints = this.getBreakpoints();
        let mediaQueries = [];

        Object.keys(breakpoints).forEach((key) => {
            let nextKey = parseInt(key) + 1;
            let breakpoint = breakpoints[key];
            let minWidth = breakpoint.size;
            let maxWidth = breakpoints[nextKey] ? breakpoints[nextKey].size - 1 : null;

            if(minWidth) mediaQueries[breakpoint.alias + '_up'] = '(min-width: ' + minWidth + 'px)';
            if(maxWidth) mediaQueries[breakpoint.alias + '_down'] = '(max-width: ' + maxWidth + 'px)';
            if(minWidth && maxWidth) mediaQueries[breakpoint.alias + '_only'] =  '(min-width: ' + minWidth + 'px) and (max-width: ' + maxWidth + 'px)';
        });

        return mediaQueries;
    }

    generateCustomQueryAliases(){
        let breakpoints =  config.custom;
        let mediaQueries = [];

        Object.keys(breakpoints).forEach((key) => {
            let nextKey = parseInt(key) + 1;
            let breakpoint = breakpoints[key];
            let minWidth = breakpoint.size;
            let maxWidth = breakpoints[nextKey] ? breakpoints[nextKey].size - 1 : null;

            if(minWidth) mediaQueries[breakpoint.alias + '_up'] = '(min-width: ' + minWidth + 'px)';
            if(maxWidth) mediaQueries[breakpoint.alias + '_down'] = '(max-width: ' + maxWidth + 'px)';
            if(minWidth && maxWidth) mediaQueries[breakpoint.alias + '_only'] =  '(min-width: ' + minWidth + 'px) and (max-width: ' + maxWidth + 'px)';
        });

        return mediaQueries;
    }
};
