import React from 'react';
import FavouriteManager from '../../services/favourite-manager';

export default class ShortlistButton extends React.Component {

    constructor (props){
        super(props);
        this.favouriteManager = new FavouriteManager;
        this.state = { favorite: this.favouriteManager.isFavourite(this.props.type, this.props.id) }
    }

    componentDidMount() {
        this.favouriteManager.onChange(this.onShortlistChange);
    }

    onShortlistChange = () => {
        let favorite = this.favouriteManager.isFavourite(this.props.type, this.props.id);
        this.setState({ favorite: favorite });
    };

    onClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let doFavoriteAction = (callback) => { callback(this.props.type, this.props.id) };
        if(this.state.favorite) {
            doFavoriteAction(this.favouriteManager.removeFavourite);
            window.dataLayer.push({ event: 'shortlist_remove', shortlistType: this.props.type, shortlistId: this.props.id, url: window.location.pathname });
        } else {
            doFavoriteAction(this.favouriteManager.addFavourite);
            window.dataLayer.push({ event: 'shortlist_add', shortlistType: this.props.type, shortlistId: this.props.id, url: window.location.pathname });
        }
    };

    renderRemove = () => {
        return (
            <span className={this.props.className} onClick={this.onClick}>
                <i className="fa fa-heart" aria-hidden="true" /> { this.props.removeText }
            </span>
        );
    };

    renderAdd = () => {
        return (
            <span className={this.props.className} onClick={this.onClick}>
                <i className="fa fa-heart-o" aria-hidden="true" /> { this.props.addText }
            </span>
        );
    };

    render () {
        return this.state.favorite ?  this.renderRemove() : this.renderAdd();
    }
}