import AbstractDataAttributeBehaviour from "./abstract-data-attribute-behaviour";

export default class MobileMenuBehavior extends AbstractDataAttributeBehaviour {

    attrSelectors = {
        container: 'data-menu-icon',
        navLinks: 'data-menu-links',
        dropDownItem: 'data-dropdown-menu-item',
        goBackItem: 'data-menu-go-back',
        expandLink: 'data-menu-expand-link'
    };

    getContainerSelector()
    {
        return '[' + this.attrSelectors.container + ']';
    }

    initContainer(container)
    {
        var navLinks = document.querySelector('['+this.attrSelectors.navLinks+']');
        var dropDownMenus = document.querySelectorAll('['+this.attrSelectors.dropDownItem+']');
        let expandLinks = document.querySelectorAll('['+this.attrSelectors.expandLink+']');

        container.addEventListener('click', (e) => {
            e.preventDefault();
            container.classList.toggle('is-active');
            navLinks.classList.toggle('show-for-large');
        });

        for (let i = 0; i < expandLinks.length; i++) {
            let _this = expandLinks[i];

            _this.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                _this.parentNode.querySelector('.drop-down-menu').classList.toggle('active');
            });
        }
    }
}