import AbstractDataAttributeBehaviour from "./abstract-data-attribute-behaviour";

export default class CountdownBehavior extends AbstractDataAttributeBehaviour {
    attrNames = {
        'container': 'data-countdown',
    };

    getContainerSelector() {
        return '[' + this.attrNames.container + ']';
    }

    initContainer(container)
    {
        const countDown = container.dataset.countdown;
        const countDownDate = new Date(countDown).getTime();

        let days = document.createElement('span');
        days.classList.add('item', 'day');
        container.appendChild(days);

        let daysDivider = document.createElement('span');
        daysDivider.classList.add('countdown-divider');
        container.appendChild(daysDivider);

        let hours = document.createElement('span');
        hours.classList.add('item', 'hour');
        container.appendChild(hours);

        let hoursDivider = document.createElement('span');
        hoursDivider.classList.add('countdown-divider');
        container.appendChild(hoursDivider);

        let minutes = document.createElement('span');
        minutes.classList.add('item', 'minute');
        container.appendChild(minutes);

        let seconds = document.createElement('span');
        seconds.classList.add('item','second');
        container.appendChild(seconds);

        const x = setInterval(function() {
            const now = new Date().getTime();
            const distance = countDownDate - now;

            if (distance <= 0) {
                container.classList.add('expired');
            } else if (distance >= 0) {
                days.innerHTML = Math.floor(distance / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
                daysDivider.innerHTML = ':';
                hours.innerHTML = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0');
                hoursDivider.innerHTML = ':';
                minutes.innerHTML = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
                seconds.innerHTML = Math.floor((distance % (1000 * 60)) / 1000).toString().padStart(2, '0');
            }
        }, 1000);
    }
}