import AbstractDataAttributeBehaviour from "./abstract-data-attribute-behaviour";

export default class HideShowBehaviour extends AbstractDataAttributeBehaviour {
    attrSelectors = {
        container: 'data-hide-show',
        hideLink: 'data-hide-show-close',
        isMobileOnly: 'data-mobile-only'
    };

    mobileBreakpoint = 1024;

    createCustomEvent(eventName, element)
    {
        return new CustomEvent(eventName, { detail: element });
    }

    getContainerSelector()
    {
        return '[' + this.attrSelectors.container + ']';
    }

    onClickShow(container, targetElement)
    {
        if (targetElement.classList.contains('hide')) {
            this.showElement(container,targetElement);
        } else {
            this.hideElement(container, targetElement);
        }
    }

    isMobileOnly(container)
    {
        return container.hasAttribute(this.attrSelectors.isMobileOnly);
    }

    showElement(container, element)
    {
        container.classList.add('active');
        element.classList.remove('hide');
        const event = this.createCustomEvent('hideShowBehaviourOpen', element);
        document.dispatchEvent(event);
    }

    hideElement(container, element)
    {
        container.classList.remove('active');
        element.classList.add('hide');
        const event = this.createCustomEvent('hideShowBehaviourClose', element);
        document.dispatchEvent(event);
    }

    initContainer(container)
    {
        let targetSelector = container.getAttribute(this.attrSelectors.container);
        let targetElements = document.querySelectorAll(targetSelector);
        let hideLinks = document.querySelectorAll('['+this.attrSelectors.hideLink+'="'+targetSelector+'"]');

        container.addEventListener('click', (e) => {
            if (this.isMobileOnly(container) && window.innerWidth >= this.mobileBreakpoint) {
                return false;
            }

            e.preventDefault();
            e.stopPropagation();

            targetElements.forEach( targetElement => {this.onClickShow(container, targetElement)});
        });

        for (let i = 0; i < hideLinks.length; i++) {
            hideLinks[i].addEventListener('click', (e) => {
                if(e.target !== e.currentTarget) {
                    return;
                }

                e.preventDefault();
                e.stopPropagation();
                // this.hideElement(container, targetElement);
                targetElements.forEach( targetElement => {this.onClickShow(container, targetElement)});
            })
        }
    }
}