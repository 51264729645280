//@flow

import React from "react";
import Scrollable from "./scrollable";
import Radio from "./radio";

type FilterProps = {
    choices: Object,
    value: string|null,
    onUpdate: (value:any) => void,
    label: string,
    placeholder: string|null,
    className: string,
    other: () => React$Element<*>
}

type FilterState = {
    value: string|null,
    placeholder: string|null,
}

export default class Filter extends React.Component<FilterProps, FilterState> {

    static defaultProps = {
        checked: false,
        placeholder: null,
        label: '',
    };

    constructor(props: FilterProps){
        super(props);

        this.state = {
            value: this.props.value,
            placeholder: this.props.placeholder
        };
    }

    componentWillReceiveProps(nextProps: FilterProps){
        if(this.state.placeholder != nextProps.placeholder) this.setState({placeholder: nextProps.placeholder});
        if(this.state.value !== nextProps.value) {

            let otherSelected = nextProps.value ? !this.props.choices.hasOwnProperty(nextProps.value) : false;
            this.setState({
                otherSelected: otherSelected,
                value: nextProps.value
            });
        }
    }

    isSelected = (item: string ): boolean => {
       let selected = false;

        if(Array.isArray(this.props.value)) {
            selected = this.props.value.includes(item);
        } else {
            selected = this.props.value === item;
        }

        return selected;
    };

    onSelect = (item: string): void => {
        this.props.onUpdate(item);
    };

    renderChoices = ():Object => {
        return Object.keys(this.props.choices).map( (key) => {
            let item = this.props.choices[key];
            return (
                <li key={item} className="facet--item active" >
                    <Radio label={ item } selected={  this.isSelected(key) } onChange={ () => this.onSelect(key) }/>
                </li>
            )
        });
    };

    renderOther = ():React$Element<*> => {
        if( !this.props.other ) return (<></>);
        return (<li key="other" className='facet--item active'><Radio label="Other" selected={  this.isSelected('other') } onChange={ (checked) => this.onSelect('other') }/></li>);
    };

    render(){
        return(
            <div className={"facet " + this.props.className }>
                <div className="facet--header">
                    <h6 className="facet--heading" >{ this.props.label}</h6>
                    <span className="facet--clear" onClick={ () =>  this.props.onUpdate([]) }>Clear</span>
                </div>
                <Scrollable className="facet--simple-bar">
                    <ul className="facet--list">
                        { this.renderChoices() }
                        { this.renderOther() }
                    </ul>

                    { this.props.other && this.isSelected('other') &&  this.props.other() }
                </Scrollable>
            </div>
        );
    }
}