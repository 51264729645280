// @flow

import React from "react";
import Search from "./search";
import Scrollable from "./scrollable";
import Checkbox from "./checkbox";

type FacetProps = {
    label: string,                        // The label shown before the collection of choices
    placeholder?: string,                 // The placeholder used in the filter search input
    className: string,
    choices?: Object,                     // Set choices to show if passed as a prop if no choices defaults to use the choices set in data
    data: Object,                         // Facet choices with facet count will be used as choice options if no choices are set
    value: Array<string>,                 // The collection of selected values as string
    filterable: boolean,                  // Option to turn on or off the search filter
    onUpdate: (value:any) => void,        // Call back when ever a choice gets selected
}

type FacetState = {
    data: Object,
    value: Array<string>,
    filtered: Array<string>
}

export default class Facet extends React.Component<FacetProps, FacetState> {

    static defaultProps = {
        placeholder: '',
        filterable: true,
        className: ''
    };

    constructor(props: FacetProps){
        super(props);

        this.state = {
            data: this.props.data||{},
            value: this.props.value||[],
            filtered: []
        };
    }

    componentWillReceiveProps(nextProps: FacetProps){
        if(this.state.data !== nextProps.data) this.setState({data:nextProps.data});
        if(this.state.value !== nextProps.value) this.setState({value: typeof nextProps.value !== "undefined" ? nextProps.value||[] : []});
    }

    onSearchComplete = (items:Array<string>):void => {
        this.setState({filtered:items});
    };

    getFilterIndex = (item:any):number => {
        return  this.state.value ?  this.state.filtered.findIndex(sel => sel === item) : -1;
    };

    isFacetFiltered = (item:string):boolean => {
        return this.state.filtered ? (this.getFilterIndex(item) >= 0) : true;
    };

    getFacetIndex = (item:any):number => {
        return this.state.value ? this.state.value.findIndex(sel => sel === item) : -1;
    };

    isFacetSelected = (item:string):boolean => {
        return this.getFacetIndex(item) >= 0;
    };

    onSelectFacet = (item: string) => {
        let curSelected = this.state.value ? [...this.state.value] : [];
        let index = this.getFacetIndex(item);
        index >= 0 ? curSelected.splice(index, 1) : curSelected.push(item);
        this.props.onUpdate(curSelected);
    };

    renderItems = ():Object => {
        if( !this.state.data ) return (<> </>);
        let sortedItems = Object.keys(this.state.data).sort( (a,b) => {
            //if(this.isFacetSelected(a) && !this.isFacetSelected(b)) return -1;
            //if(!this.isFacetSelected(a) && this.isFacetSelected(b)) return 1;
            if(this.state.data[a] === 0 && this.state.data[b] !== 0) return 1;
            if(this.state.data[a] !== 0 && this.state.data[b] === 0) return -1;
            if(this.isFacetFiltered(a) && !this.isFacetFiltered(b)) return -1;
            if(!this.isFacetFiltered(a) && this.isFacetFiltered(b)) return 1;
            return a < b ? -1: 1;
        }) ;

        return sortedItems.map( item => (
            <li key={item} className={ ((this.isFacetFiltered(item) || this.state.filtered.length >= 1) || this.state.data[item] !== 0) ? 'facet--item active' : 'facet--item' }>
                <Checkbox label={ `${item} (${this.props.data[item]})`} checked={  this.isFacetSelected(item) } onChange={ () => this.onSelectFacet(item) }/>
            </li>
        ));
    };

    renderChoices = (choices: Object):Object => {
        if(!choices) return (<></>);

        return Object.keys(choices).map( key => {
            let item = choices[key];

            if( (this.state.data && Object.keys(this.state.data).length > 0) === false ) return (
                <li key={item} className={ 'facet--item active' }>
                    <Checkbox label={ item } checked={  this.isFacetSelected(key) } onChange={ () => this.onSelectFacet(key) }/>
                </li>
            );

            return (
                <li key={item} className={ ((this.isFacetFiltered(key) || this.state.filtered.length >= 1) || this.state.data[item] !== 0) ? 'facet--item active' : 'facet--item' }>
                    <Checkbox label={ key in this.props.data ? `${item} (${this.props.data[key]})` : item} checked={  this.isFacetSelected(key) } onChange={ () => this.onSelectFacet(key) }/>
                </li>
            )
        });
    };

    render(){

        return(
            <div className={"facet " + this.props.className }>
                <div className="facet--header">
                    <h6 className="facet--heading" >{ this.props.label}</h6>
                    <span className="facet--clear" onClick={ () =>  this.props.onUpdate([]) }>Clear</span>
                </div>
                { this.props.filterable && <Search placeholder={ this.props.placeholder } items={ this.state.data } onComplete={ this.onSearchComplete } /> }
                <Scrollable className="facet--simple-bar">
                    <ul className="facet--list">
                        { this.props.choices ? this.renderChoices(this.props.choices) : this.renderItems() }
                    </ul>
                </Scrollable>
            </div>
        );
    }
}