export default class RegexAssertion {

    constructor(regex, flags) {
        this.regex = regex;
        this.flags = flags;
    };

    test = (value) => {
        if(value === null || value === undefined) value = '';
        let regex = new RegExp(this.regex, this.flags);
        let result = regex.test(value);
        return result;
    }
};