import AbstractHandler from "./abstractHandler";

export default class CommunicationPreferencesHandler extends AbstractHandler {

    constructor(element, formValidator) {
        super(element, formValidator);

        this.inputs = [element];
        this.labels = [];
    }

    validate = () => {
        let checkedInputs = this.element.querySelectorAll('input[type="checkbox"]:checked');
        this.errors = [];

        let valid = checkedInputs.length >= 1;

        if(valid) {
           this.validationState = this.STATUS_VALID;
        } else {
            this.validationState = this.STATUS_INVALID;
            this.errors.push('Please select at least one communication preference.');
        }

        if(valid) this.validationState = this.STATUS_VALID;
        this.updateDOM();

        return valid;
    }
}