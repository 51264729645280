import CountdownBehavior from "../behaviours/countdown-timer-behaviour";

require("../../scss/app-homepage.scss");

import Glide, { Autoplay, Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

import BackgroundImageBehaviour from '../behaviours/background-image-behaviour';
import MobileMenuBehavior from "../behaviours/mobile-menu-behaviour";
import EqualizeBehavior from "../behaviours/equalize-behaviour";
import ShortlistCount from "../jsx/shortlist/shortlist-count";
import ShortlistButton from '../jsx/shortlist/shortlist-button';
import SearchTemplateBasic from "../jsx/search/template-basic";
import HideShowBehaviour from "../behaviours/hide-show-behaviour";
import ToggleClassBehavior from "../behaviours/toggle-class-behaviour";
import Application from "./application";

let config = {};
config.behaviours = {
    "BackgroundImage": new BackgroundImageBehaviour(),
    "MobileMenuBehaviour": new MobileMenuBehavior(),
    "EqualizeBehaviour": new EqualizeBehavior(),
    "HideShowBehaviour": new HideShowBehaviour(),
    "ToggleClassBehaviour": new ToggleClassBehavior(),
    "CountdownBehaviour": new CountdownBehavior()
};

config.components = {
    "shortlist-count": ShortlistCount,
    "search-form": SearchTemplateBasic,
    "shortlist-button": ShortlistButton,
}

new Application(config);

window.glide = {};

if(document.querySelector('#cruise-lines-carousel')) {
    window.glide.cruiseLinesSlider =  new Glide('#cruise-lines-carousel', {autoplay: false, perView: 5, bound: true, breakpoints: { 480: { perView: 1}, 600: { perView: 2}, 1025: { perView: 3} }})
        .mount({Autoplay, Controls, Breakpoints, Swipe})
        .on('run.after', () => { window.lazy.update() });
}

if (document.querySelector('.review-slider')) {
    window.glide.reviewSlider = new Glide('.review-slider', {autoplay: false, perView: 2, bound: true, breakpoints: { 740: { perView: 1}  }})
        .mount({Autoplay, Controls, Breakpoints, Swipe})
        .on('run.after', () => { window.lazy.update() });
}