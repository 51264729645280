 // @flow
import React, { createRef } from "react";
import InputRange from "react-input-range";
import Scrollable from "../interface/scrollable";

import BreakpointManager from "../../services/breakpoint-manager";

import {SearchForm, getYearOptions, getMonthOptions, getDayOptions, getFlexibilityOptions, getDurationOptions } from "./form";
import SoloToggle from "../solo-toggle";
import Facet from "../interface/facet";
import Toggle from "../interface/toggle";
import AutoComplete from "../interface/autocomplete";
import UiModal from "../interface/ui-modal";
import Checkbox from "../interface/checkbox";
import Filter from "../interface/filter";

import type {
    CruiseSearchType
} from "../../flow/api_types";

type SearchTemplateBasicProps = {
    solo: boolean,
    searchParams: CruiseSearchType,
}

type SearchTemplateBasicState = {
    modal: string,
    advanced: boolean,
    open: boolean,
    breakpoints: Object,
    isMobile: boolean,
    priceRange: {min: number,max: number},
    searchParams: CruiseSearchType,
}

export default class SearchTemplateBasic extends React.Component<SearchTemplateBasicProps, SearchTemplateBasicState> {
    constructor(props: SearchTemplateBasicProps) {
        super(props);

        const { topDestinations, ...rest } = this.props.searchParams;

        this.BreakpointManager = new BreakpointManager();
        this.state = {
            modal: '',
            advanced: false,
            open: false,
            breakpoints: this.BreakpointManager.getActiveBreakPoint(),
            isMobile: this.BreakpointManager.getActiveBreakPoint().indexOf("md_down") >= 0,
            priceRange: {min: this.props.searchParams.minValue||0 ,max: this.props.searchParams.maxValue||20000},
            searchParams: rest
        };

        this.formRef = createRef();
    }

    componentDidMount(){
        this.BreakpointManager.onChange( this.onWindowResize );
    };


    getConfig = (): Object => {
        let configObject = {
            reset: {
                'renderMethod': this.renderReset
            },
            priceRange: {
                'renderMethod': this.renderPriceRangeComponent,
                'label': 'Refine price',
            },
            departureYear: {
                'renderMethod': this.renderModal,
                'iconClass' : 'fa fa-calendar-alt',
                'title': 'Year',
                'placeholder': 'Year',
                'type': Facet,
                'props': {
                    'filterable': false,
                    'choices': getYearOptions(),
                    'className': 'in-modal',
                    'label': 'Select one or more year(s)',
                    'placeholder': 'Select Years',
                }
            },
            departureMonth: {
                'renderMethod': this.renderModal,
                'iconClass' : 'fa fa-calendar-alt',
                'title': 'Month',
                'placeholder': 'Month',
                'type': Facet,
                'props': {
                    'filterable': false,
                    'choices': getMonthOptions(),
                    'className': 'in-modal',
                    'label': 'Select one or more month(s)',
                    'placeholder': 'Select Months',
                }
            },
            departureDay: {
                'renderMethod': this.renderDays,
                'title': 'Day',
                'placeholder': 'Day',
                'iconClass' : 'fa fa-calendar-alt',
                'modalClass': 'relative-width',
                'type': Facet,
                'props': {
                    'className': 'in-modal column-count-1',
                    'filterable': false,
                    'label': 'Select day(s)',
                    'placeholder': 'Select days',
                }
            },
            departureFlexibility: {
                'renderMethod': this.renderFlexibility,
                'title': 'Flexibility',
                'iconClass' : 'fa fa-exchange',
                'placeholder': 'Flexibility',
                'type': Filter,
                'props': {
                    'className': 'in-modal',
                    'choices': getFlexibilityOptions(),
                    'label': 'How flexible is your departure?',
                }
            },
            duration: {
                'renderMethod': this.renderDuration,
                'type': Filter,
                'iconClass' : 'fa fa-clock',
                'title': 'Duration',
                'placeholder': 'Duration',
                'props': {
                    'className': 'in-modal',
                    'label': 'Select cruise duration?',
                    'placeholder': 'duration',
                    'choices': getDurationOptions(),
                }
            },
            places: {
                'renderMethod': this.renderModal,
                'type': AutoComplete,
                'title': 'Destination',
                'iconClass' : 'fa fa-pin',
                'placeholder': 'Enter destination(s)',
                'props': {
                    'renderAdditionalContent': this.renderPlacesByAnd,
                    'className': 'in-modal',
                    'label': 'Where would you like to go?',
                    'placeholder': 'Enter destination(s)',
                    'endpoint': '/api/cruise-search/places',
                    'defaultLabel': 'Our Top Destinations',
                    'searchLabel': 'Found Destinations',
                    'defaultOptions': {
                        '0':'Caribbean',
                        '1':'Norway',
                        '2':'Baltic and Scandinavia',
                        '3':'Mediterranean',
                        '4':'Canary Islands',
                        '6':'Australia',
                        '7':'Canada',
                        '8':'Danube River Cruises',
                    }
                }
            },
            placesByAnd: {
                'renderMethod': this.renderDepartUk,
                'type': Checkbox,
                'label': 'Only show cruises that include all the above',
                'props': {
                    'className': 'small m-top-sm'
                }
            },
            lineName: {
                'renderMethod': this.renderModal,
                'type': Facet,
                'title': 'Lines',
                'label': 'Cruise lines',
                'iconClass' : 'fa fa-ship',
                'placeholder': 'Select cruise line',
                'props': {
                    'className': 'in-modal',
                    'label': 'Please select cruise lines?',
                    'placeholder': 'Search cruise lines',
                }

            },
            shipName: {
                'renderMethod': this.renderModal,
                'type': Facet,
                'title': 'Ships',
                'label': 'Cruise Ships',
                'iconClass' : 'fa fa-ship',
                'placeholder': 'Select cruise ship',
                'props': {
                    'className': 'in-modal',
                    'label': 'Please select cruise ships?',
                    'placeholder': 'Enter cruise ships',
                }
            },
            categories: {
                'renderMethod': this.renderModal,
                'type': Facet,
                'title': 'Types',
                'label': 'Cruise Types',
                'iconClass' : 'fa fa-ship',
                'placeholder': 'Select cruise type',
                'props': {
                    'className': 'in-modal',
                    'label': 'Please select cruise types?',
                    'placeholder': 'Enter cruise types',
                }
            },
            departurePort: {
                'renderMethod': this.renderModal,
                'type': Facet,
                'title': 'Ports',
                'label': 'Where would you like to sail from',
                'placeholder': 'Enter departure port',
                'iconClass' : 'fa fa-location-arrow',
                'props': {
                    'className': 'in-modal',
                    'label': 'Please select departure ports?',
                    'placeholder': 'Search departure port',
                }
            },
            departsUk: {
                'renderMethod': this.renderDepartUk,
                'type': Checkbox,
                'label': 'Sail from a UK port?',
            },
            submit: {
                'renderMethod': this.renderSubmit
            }
        };

        if (this.props.searchParams.topDestinations) {
            const topDestinations = {...this.props.searchParams.topDestinations.split(',').map(dest => dest.trim())};
            if (topDestinations) configObject.places.props.defaultOptions = topDestinations;
        }

        return configObject;
    };

    onWindowResize = (breakpoints: Object) => {
        if(this.state.breakpoints.join('-') !== breakpoints.join('-')) {
            // @flowIgnore
            document.body.classList.remove('no-scroll-md-down');
            this.setState({
                open: false,
                modal: '',
                breakpoints: breakpoints,
                isMobile: (breakpoints.indexOf("md_down") >= 0)
            })
        }
    };

    onWindowPopstate = (searchParams: CruiseSearchType): void => {
        this.setState({
            priceRange: {
                min: searchParams.minValue||0,
                max: searchParams.maxValue||20000
            }
        });
    };

    onResetSearch = (callback) => {
        this.setState({ priceRange: { min: 0, max: 20000 } }, callback());
        if (this.state.searchParams.resetRedirectUrl) window.location.href = this.state.searchParams.resetRedirectUrl;
    };

    openSearch = () => {
        this.setState({open: true});

        // @flowIgnore
        document.body.classList.add('no-scroll-md-down');
    };

    closeSearch = () => {
        this.setState({open: false});

        // @flowIgnore
        document.body.classList.remove('no-scroll-md-down');
    };

    toggleAdvanced = (): void => {
        this.setState({advanced:!this.state.advanced });
    };

    openField = (propertyPath: string): void => {
        this.setState({modal: propertyPath});
    };

    closeField = (currentOnly: boolean = true): void => {
        this.setState({open: currentOnly, modal: ''});
    };

    toggleField = (propertyPath: string): void => {
        let modal = this.state.modal === propertyPath ? '' : propertyPath;
        this.setState({modal: modal});
    };

    scrollToSearch = (e) => {
        clearInterval(window.pageScroll);

        let to = 192;
        let difference = to - window.pageYOffset;
        let perTick = difference / 100;

        window.pageScroll = setInterval(() => {
            window.scrollTo(window.pageXOffset, window.pageYOffset + perTick);
            if (to - window.pageYOffset < perTick) {
                clearInterval(window.pageScroll);
            }
        }, 1);
    };

    renderPriceRangeComponent = (propertyPath: string, form: Object, config: Object): React$Element<*> => {
        let minLabel = parseInt(this.state.priceRange.min) > 1 ? `Min £${  this.state.priceRange.min.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }pp` : `No Minimum`;
        let maxLabel = parseInt(this.state.priceRange.max) < 20000 ? `Max £${  this.state.priceRange.max.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }pp` : `No Maximum`;
        return (
            <div className="input-range--wrapper">
                <InputRange type="range" step={100} minValue={0} maxValue={20000} value={ this.state.priceRange } onChange={ value => this.setState({ priceRange: value }) } onChangeComplete={ (value) => {
                    form.onFieldUpdate('minValue', value.min > 1 ? value.min : null);
                    form.onFieldUpdate('maxValue', value.max < 20000 ? value.max : null);
                }}/>
                <p className="input-range--label">{ minLabel }</p>
                <p className="input-range--label">{ maxLabel }</p>
            </div>
        );
    };

    renderFlexibility = (propertyPath: string, form: Object, config: Object): React$Element<*> => {
        config.props.choices = getFlexibilityOptions();
        if(!(form.state.searchParams.departureYear && form.state.searchParams.departureMonth && form.state.searchParams.departureDay)) config.disabled = false;
        return this.renderModal(propertyPath,form, config);
    };

    renderDuration = (propertyPath: string, form: Object, config: Object): React$Element<*> => {
        config.props.other  = () =>
            <div className="facet--other">
                <h6 className="facet--heading mt-2 mb-2">please specify your preferred cruise duration</h6>
                <div className="search-field">
                    <p className="search-field--label">Nights</p>
                    <div className="search-field--group">
                        <input value={ form.state.searchParams.minNights } onChange={ (e) => form.onFieldUpdate('minNights', e.target.value ) } type="text"/>
                        <span className="pl-3 pr-3"> to </span>
                        <input value={ form.state.searchParams.maxNights } onChange={ (e) => form.onFieldUpdate('maxNights', e.target.value ) } type="text"/>
                    </div>
                </div>
            </div>;

        return this.renderModal(propertyPath,form, config);
    };

    renderDays = (propertyPath: string, form: Object, config: Object): React$Element<*> => {
        let month = 0;
        let year = form.state.searchParams.departureYear ? form.state.searchParams.departureYear[0] : 0;

        if(form.state.searchParams.departureMonth) {
            let monthIndex = Date.month_names_short.indexOf(form.state.searchParams.departureMonth[0]) + 1;
            month = monthIndex >= 0 ? monthIndex : 0
        }

        config.props.choices = getDayOptions(month, year);
        return this.renderModal(propertyPath, form, config );
    };

    renderDisabledModal = (propertyPath: string, form: Object, config: Object): React$Element<*> => {
        return ( <div className="search-field--input disabled">
            { config.iconClass && <i className={config.iconClass} /> } { config.placeholder }
        </div> )
    };

    renderModal = (propertyPath: string, form: Object, config: Object): React$Element<*> => {
        if("disabled" in config && config.disabled === false) return this.renderDisabledModal(propertyPath, form, config);

        let props = {
            value: form.state.searchParams[propertyPath],
            data: form.state.facets[propertyPath],
            'onUpdate': items => form.onFieldUpdate(propertyPath, items),
            ...config.props
        };

        let placeholder = config.placeholder;
        if(form.state.searchParams[propertyPath] ) {
            if (typeof form.state.searchParams[propertyPath] === 'object') {
                placeholder = (config.props.choices ? form.state.searchParams[propertyPath].map((item) => config.props.choices[item]) : form.state.searchParams[propertyPath]).join(', ');
            }

            if (typeof form.state.searchParams[propertyPath] === 'string') {
                placeholder = form.state.searchParams[propertyPath];
            }
        }

        return (<>
            <div
                className={this.state.modal === propertyPath ? "search-field--input active": "search-field--input" }
                tabIndex="0"
                onClick={ () => { this.openField(propertyPath)} }
            >
                { config.iconClass && <i className={config.iconClass} /> } { placeholder }
            </div>

            <UiModal
                showHeader={true}
                title={ config.title }
                modalClassName={config.modalClass}
                open={ this.state.modal === propertyPath }
                onBack={ () => this.closeField(true) }
                onClose={ () => this.closeField(false) }
            >
                { React.createElement( config.type, props )}
            </UiModal>
        </>);
    };

    renderPlacesByAnd = () => {
        const formField = this.formRef.current
          .getComponents().placesByAnd;

        return (<>{React.createElement(formField.type, formField.props)}</>);
    }

    renderDepartUk  = (propertyPath: string, form: Object, config: Object): React$Element<*> => {
        let props = {
            checked: form.state.searchParams[propertyPath],
            label: config.label,
            'onChange': items => form.onFieldUpdate(propertyPath, items),
            ...config.props
        };

        return (<>{ React.createElement( config.type, props )}</>);
    };

    renderReset = (propertyPath: string, form: Object, config: Object): React$Element<*> => {
        return (<p className="homepage-search--reset" onClick={ () => this.onResetSearch( form.resetSearch )  }><span className="hide-for-large">clear</span> <span className="show-for-large">Reset search<i className="icn icn-refresh"/></span></p>);
    };

    renderSubmit = (propertyPath: string, form: Object, config: Object): React$Element<*> => {
        return (<a className="homepage-search--button button expanded no-icon" href={`/find-your-cruise?${form.state.http_query}`}>SEARCH CRUISES <i className="fa fa-search" /></a>);
    };

    renderFields = (formFields: Object): React$Element<*> => {
        let fields = (<>
            <div className="homepage-search--row">
                <h6 className="homepage-search--modal-heading">Filter your search results </h6>
                <div className="homepage-search--modal-heading">{ formFields.reset }</div>
            </div>

            <div className="homepage-search--row">
                <div className="search-field" onClick={ this.scrollToSearch }>
                    <p className="search-field--label">Destination </p>
                    { formFields.places}
                </div>

                <div className="search-field" onClick={ this.scrollToSearch }>
                    <p className="search-field--label">Year</p>
                    { formFields.departureYear }
                </div>

                <div className="search-field" onClick={ this.scrollToSearch }>
                    <p className="search-field--label">Month</p>
                    { formFields.departureMonth }
                </div>

                <div className="search-field" onClick={ this.scrollToSearch }>
                    <p className="search-field--label">Cruise line</p>
                    {formFields.lineName}
                </div>

                {!this.state.advanced && (
                  <div className="search-field search-field--submit">
                      <p className="search-field--label"></p>
                      {formFields.submit}
                  </div>
                )}
            </div>

            {this.state.advanced && <div className="homepage-search--row">
                <div className="search-field" onClick={ this.scrollToSearch }>
                    <p className="search-field--label">Cruise ship</p>
                    {formFields.shipName}
                </div>
                <div className="search-field" onClick={ this.scrollToSearch }>
                    <p className="search-field--label">Cruise type</p>
                    {formFields.categories}
                </div>
                <div className="search-field" onClick={ this.scrollToSearch }>
                    <p className="search-field--label">Where would you like to sail from?</p>
                    {formFields.departurePort}
                </div>
                <div className="search-field" onClick={ this.scrollToSearch }>
                    {formFields.departsUk}
                </div>
            </div> }

            {this.state.advanced && <div className="homepage-search--row">
                <div className="search-field position-relative" onClick={ this.scrollToSearch }>
                    <p className="search-field--label">Day</p>
                    { formFields.departureDay }
                </div>

                <div className="search-field" onClick={ this.scrollToSearch }>
                    <p className="search-field--label">Flexibility</p>
                    { formFields.departureFlexibility }
                </div>

                <div className="search-field" onClick={ this.scrollToSearch }>
                    <p className="search-field--label">Duration</p>
                    { formFields.duration }
                </div>
            </div> }



            {this.state.advanced && <div className="homepage-search--row">
                <div className="search-field" onClick={ this.scrollToSearch }>
                    <p className="search-field--label">View prices as:</p>
                    <SoloToggle checked={this.props.solo} toggleOnly={true}/>
                </div>
                <div className="search-field search-field--slider" onClick={ this.scrollToSearch }>
                    <p className="search-field--label">Refine price</p>
                    {formFields.priceRange}
                </div>
                <div className="search-field" onClick={ this.scrollToSearch }>
                    <p className="search-field--label"></p>
                    {formFields.submit}
                </div>
            </div>}
        </>);
        return this.state.isMobile && !this.state.modal ? <Scrollable className="homepage-search--simple-bar">{fields}</Scrollable> : fields;
    };

    renderTemplate = (formFields: Object): React$Element<*> => {
        return (
            <div className="homepage-search">
                <div className="homepage-search--header">
                    <h1 className="homepage-search--heading">Welcome to ROL Cruise</h1>
                    <div className="button no-icon expanded hide-for-large" onClick={ this.openSearch } >Search for cruises <i className="fa fa-search" /></div>
                    <span className="ml-a show-for-large">{ formFields.reset }</span>
                    <div className="homepage-search--advanced show-for-large"><span className="pr-2">Advanced search: </span><Toggle checked={this.state.advanced} onToggle={this.toggleAdvanced}/></div>
                </div>
                <div className={"homepage-search--panel" + (this.state.advanced ? " advanced" : "") + (this.state.open ? " open" : "") }>
                    <div className="homepage-search--modal-header">
                        <div className="homepage-search--modal-title">Search cruises</div>
                    </div>
                    <div className="homepage-search--modal-close" onClick={ this.closeSearch }><span>Close</span></div>

                    <div className="homepage-search--mobile-advanced hide-for-large text-center"><span className="pr-2">Advanced search: </span><Toggle checked={this.state.advanced} onToggle={this.toggleAdvanced}/></div>

                    { this.renderFields(formFields) }
                </div>

            </div>
        );
    };

    render() {
        return (
            <SearchForm ref={this.formRef} template={ this.renderTemplate } config={ this.getConfig() } searchParams={ this.state.searchParams } onWindowPopstate={ this.onWindowPopstate } useAnalytics={false} />
        )
    }
}



