import AbstractHandler from "./abstractHandler";
import RegexAssertion from "../assertions/RegexAssertion";

export default class PasswordHandler extends AbstractHandler {

    validate =() => {
        let valid = true;
        this.errors = [];

        let assertion = new RegexAssertion(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/, 'gm');
        this.validationState = this.STATUS_VALIDATING;
        this.updateDOM();

        this.inputs.forEach( input => {
            if(!assertion.test(input.value)) {
                valid = false;
                this.validationState = this.STATUS_INVALID;
                this.errors.push('Password does not meet the security requirements, please enter an alternative password');
            }
        });

        if(valid) this.validationState = this.STATUS_VALID;
        this.updateDOM();

        return valid;
    }

}