import AbstractDataAttributeBehaviour from "./abstract-data-attribute-behaviour";

export default class BackgroundImageBehaviour extends AbstractDataAttributeBehaviour {
    attrNames = {
        'container': 'data-background-image'
    }

    getContainerSelector() {
        return '[' + this.attrNames.container + ']';
    }

    initContainer(container)
    {
        var images = JSON.parse(container.getAttribute(this.attrNames.container));
        if (document.documentElement.clientWidth > 640) {
            container.style.backgroundImage = "url('"+images.large+"')";
        } else {
            container.style.backgroundImage = "url('"+images.small+"')";
        }
    }

}