// @flow
import React from "react";

type PillProps = {
    label: string,
    onClick?: (value:any) => void,
    onRemove?: (value:any) => void,     // The placeholder used in the filter search input
}

type PillState = {
    focused: boolean;
}

export default class Pill extends React.Component<PillProps, PillState> {
    static defaultProps = {
        filterable: true,
        className: ''
    };

    constructor(props: PillProps){
        super(props);
        this.state = {
            focused: false
        };
    }

    onRemove = (event: MouseEvent): void => {
      if( this.props.onRemove ) this.props.onRemove();
    };

    onDeleteKey = ():void => {
        if( this.props.onRemove ) this.props.onRemove();
    };

    onClick = (event: MouseEvent): void => {
        if( this.props.onClick ) this.props.onClick();
    };

    onEnterKey = (): void => {
        if( this.props.onClick ) this.props.onClick();
    };

    onKeyUp = (event: KeyboardEvent): void => {
        if (!this.state.focused) return;
        if(event.key === 'Delete') this.onDeleteKey();
        if(event.key === 'Enter') this.onEnterKey();
    };

    render(){
        return(
          <div className="pill removable" tabIndex="0" onKeyUp={ this.onKeyUp } onFocus={ () => this.setState({focused: true}) } onBlur={ () => this.setState({focused: false}) } >
              <span className="pill--label" onClick={ this.onClick } >{ this.props.label }</span>
              { this.onRemove && <span className="pill--remove" onClick={ this.onRemove }> <i className="icn icn-close" /> </span> }
          </div>
        );
    }
}