import AbstractHandler from "./abstractHandler";
import RegexAssertion from "../assertions/RegexAssertion";

export default class PhoneNumberHandler extends AbstractHandler {

    validate = () => {
        this.errors = [];
        let valid = true

        let value = this.element.value;
        if(value === null || value === undefined) value = '';

        if(value === '') {
            this.errors.push(this.message === undefined ? 'This is a required field' : this.message);
            this.validationState = this.STATUS_INVALID;
            valid = false;
        }

        let assertion = new RegexAssertion(/^[0-9]{9,}$/, 'gm');

        if(!assertion.test(value)) {
            this.errors.push('Telephone number must contain at least 9 numbers');
            this.validationState = this.STATUS_INVALID;
            valid = false;
        }

        if(valid) this.validationState = this.STATUS_VALID;
        this.updateDOM();

        return valid;
    }

}