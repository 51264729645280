// @flow
import React from "react";
import Scrollable from "./scrollable";

type UiModalProps = {
    showHeader: boolean,
    title: string,
    children: any,
    modalClassName: string,
    open: boolean,
    scrollable: boolean,
    onClose: () => void,
    onBack?: () => void,
}

type UiModalState = {
    open: boolean,
    scrollable: boolean
}

export default class UiModal extends React.Component<UiModalProps, UiModalState> {
    static defaultProps = {
        modalClassName: '',
        open: false,
        scrollable: false
    };

    constructor(props: UiModalProps): void
    {
        super(props);
        this.state = {
            open: this.props.open,
            scrollable: this.props.scrollable
        };
    }

    componentWillReceiveProps(nextProps: UiModalProps): void
    {
        if(this.state.scrollable !== nextProps.scrollable) this.setState({scrollable:nextProps.scrollable});
        if(this.state.open !== nextProps.open) this.setState({open:nextProps.open}, () => {
            if(nextProps.open){
                this.addClickListener();

                // @flowIgnore
                document.body.classList.add('no-scroll-md-down');
            } else {
                this.removeClickListener();

                // @flowIgnore
                document.body.classList.remove('no-scroll-md-down');
            }
        });
    }

    componentWillUnmount(): void {
        this.removeClickListener();
        // @flowIgnore
        document.body.classList.remove('no-scroll-md-down');
    }

    onClick = (e: MouseEvent): void => {
        let parent = this.refs.modal;
        let target = e.target;
        if (target instanceof Node && parent instanceof Node) {
            if (!parent.contains(target) && this.state.open) {
                this.props.onClose();
            }
        };
    };

    onBack = (): void => {
        this.props.onBack ? this.props.onBack() : this.onClose();
    };

    onClose = (): void => {
        this.props.onClose();
    };

    addClickListener = (): void => {
        document.addEventListener('click', this.onClick );
    };

    removeClickListener = (): void => {
        document.removeEventListener('click', this.onClick );
    };

    renderContent = () => {
        if(this.state.scrollable) return (
            <Scrollable className="ui-modal--simple-bar ui-modal--content">
                { this.props.children }
            </Scrollable>
        );

        return (
            <div className="ui-modal--content">
                { this.props.children }
            </div>
        )
    };

    render(){
        if (!this.state.open) return (<></>);

        return(
            <>
                <div ref="modal" className={"ui-modal " + this.props.modalClassName }>
                    { this.props.onBack && <div className="ui-modal--back" onClick={ this.onBack } /> }
                    { this.props.showHeader && <div className="ui-modal--header"><div className="ui-modal--title">{ this.props.title }</div></div> }
                    <div className="ui-modal--close" onClick={ this.onClose } />
                    { this.renderContent() }
                    <div className="ui-modal--apply" onClick={ this.onBack } >Apply</div>
                </div>
            </>
        )
    }
}