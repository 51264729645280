import AbstractHandler from "./abstractHandler";

export default class FirstNameHandler extends AbstractHandler {

    constructor(element, formValidator) {
        super(element, formValidator);

        this.minLength = 2;
    }

    validate = () => {
        let valid = true
        this.errors = [];

        let value = this.element.value;
        if(value === null || value === undefined) value = '';

        if(value === '') {
            this.errors.push(this.message === undefined ? 'Please supply first name' : this.message);
            this.validationState = this.STATUS_INVALID;
            valid = false;
        }

        if(value.slice(0, 1) === ' '){
            this.errors.push('Must not start with a space');
            this.validationState = this.STATUS_INVALID;
            valid = false;
        }

        if(value.length < this.minLength){
            this.errors.push('Must be at least ' + this.minLength + ' characters long');
            this.validationState = this.STATUS_INVALID;
            valid = false;
        }

        if(!value.match(/^[A-Za-z'‘’\-\s]+$/gm)){
            this.errors.push('Only letters, spaces and \' - are allowed');
            this.validationState = this.STATUS_INVALID;
            valid = false;
        }

        if(value.match(/('|‘|’|\-)(?![A-Z])/gm)){
            this.errors.push('Apostrophes and Hyphens should be followed by a capital letter');
            this.validationState = this.STATUS_INVALID;
            valid = false;
        }

        if(valid) this.validationState = this.STATUS_VALID;
        this.updateDOM();

        return valid;
    }
}