export default class AbstractHandler
{
    STATUS_UNVALIDATED = 0;
    STATUS_VALIDATING = 1;
    STATUS_INVALID = 5;
    STATUS_WARNING = 8;
    STATUS_VALID = 10;

    validationState = this.STATUS_UNVALIDATED;
    errors = [];
    errorElement = null;

    constructor(element, formValidator) {
        this.element = element;
        this.inputs = [element];

        if(element.tagName.toLowerCase() !== 'input' && element.tagName.toLowerCase() !== 'select' && element.tagName.toLowerCase() !== 'textarea') this.inputs = element.querySelectorAll('input, textarea, select');

        let labelQueries = [`[for="${this.element.getAttribute('id')}"]`];
        this.inputs.forEach( input => {
            labelQueries.push(`[for="${input.getAttribute('id')}"]`);
        });

        this.labels = document.querySelectorAll(labelQueries.join(','));

        this.formValidator = formValidator;

        this.element.addEventListener('change', this.onChange);
        this.timer = null;
    }

    onChange = () => {
        clearTimeout(this.timer);
        this.validationState = this.STATUS_VALIDATING;
        this.errors = [];
        this.updateDOM();
        this.timer = setTimeout(e => {
            this.validate();
        }, 600);
    }
    isValid = () => {
        return this.validationState === this.STATUS_VALID;
    }

    reset = () => {
        this.validationState = this.STATUS_UNVALIDATED;
        this.errors = [];
        this.updateDOM();
    }

    updateDOM = () => {
        this.resetDOM();
        if(this.validationState === this.STATUS_INVALID) this.setViewToInvalid();
        if(this.validationState === this.STATUS_WARNING) this.setViewToWarning();
        if(this.validationState === this.STATUS_VALID) this.setViewToValid();
    }

    resetDOM = () => {
        this.inputs.forEach( input => {
            input.classList.remove('is-valid-input', 'is-warning-input', 'is-invalid-input');
        });

        this.labels.forEach( label => {
            label.classList.remove('is-valid-label', 'is-warning-input', 'is-invalid-label');
        });

        this.removeErrorMessage();
    }

    setViewToInvalid = () => {
        this.inputs.forEach( input => {
            input.classList.add('is-invalid-input');
        });

        this.labels.forEach( label => {
            label.classList.add('is-invalid-label');
        });

        this.addErrorMessage();
    }

    setViewToWarning = () => {
        this.inputs.forEach( input => {
            input.classList.add('is-warning-input');
        });

        this.labels.forEach( label => {
            label.classList.add('is-warning-label');
        });

        this.addErrorMessage();
    }

    setViewToValid = () => {
        this.inputs.forEach( input => {
            input.classList.add('is-valid-input');
        });

        this.labels.forEach( label => {
            label.classList.add('is-valid-label');
        });

        this.removeErrorMessage();
    }

    addErrorMessage = () => {
        this.removeErrorMessage();
        let errorMessage = this.element.getAttribute('data-live-validate-error-message') ? this.element.getAttribute('data-live-validate-error-message') : this.errors[0];

        if(errorMessage){
            this.errorElement = document.createElement('small');
            this.errorElement.classList.add('form-error', 'is-visible');
            this.errorElement.innerHTML = errorMessage;

            let insertAfterElement =  this.element.getAttribute('data-live-validate-error-after') ? document.querySelector(this.element.getAttribute('data-live-validate-error-after'))  : this.element;
            insertAfterElement.parentNode.insertBefore(this.errorElement, insertAfterElement.nextSibling)
        }
    }

    removeErrorMessage = () => {
        if(this.errorElement) {
            this.errorElement.remove();
            this.errorElement = null;
        }
    }

}