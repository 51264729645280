// @flow
import React from 'react';

type RadioProps = {
    selected: boolean,
    label: string,
    onChange: (checked: boolean) => void
}

type RadioState = {
    selected: boolean,
    focused: boolean
}

export default class Checkbox extends React.Component<RadioProps,RadioState> {

    static defaultProps = {
        selected: false,
        focused: false,
        label: '',
    };

    constructor (props: RadioProps): void {
        super(props);
        this.state = { selected: this.props.selected };
    }

    componentWillReceiveProps(nextProps: RadioProps): void {
        if(this.state.selected !== nextProps.selected) this.setState({selected: nextProps.selected });
    }

    onChange = ():void => {
        this.props.onChange(!this.state.selected)
    };

    onKeyUp = (event: KeyboardEvent): void => {
        if (!this.state.focused) return;
        if(event.key === 'Enter') this.onChange();
    };

    render = () => <div onClick={ this.onChange } className={this.state.selected ? "radio active" : "radio"}>
        <div tabIndex="0" className="radio--box" onKeyUp={ this.onKeyUp } onFocus={ () => this.setState({focused: true}) } onBlur={ () => this.setState({focused: false}) }/>
        <div className="radio--label">{ this.props.label }</div>
    </div>


}