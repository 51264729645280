export default class CacheManager {
    constructor() {
        this.storage = window.localStorage; //usr localStorage to store API requests
    }

    getItem(name)
    {
        return this.storage.getItem(name);
    }

    setItem(name, value, expires)
    {
        expires = (expires===undefined || expires==null) ? 1300 : expires; // default: 30m
        let date = new Date();
        let schedule = Math.round((date.setSeconds(date.getSeconds()+expires))/1000);
        this.storage.setItem(name, value);
        this.storage.setItem(name+'_time', schedule);
    }

    removeItem(name)
    {
        this.storage.removeItem(name);
        this.storage.removeItem(name+'_time');
    }

    isItemExpired(name)
    {
        let date = new Date();
        let current = Math.round(+date/1000);
        let stored_time = Math.round(this.storage.getItem(name+'_time')); //value should already be rounded this will set null  / false to 0.

        if (stored_time < current) {
            this.removeItem(name);

            return true;
        } else {
            return false;
        }
    }
}