// @flow
import React from 'react';

type CheckboxProps = {
    checked: boolean,
    label: string,
    onChange: (checked: boolean) => void,
}

type CheckboxState = {
    checked: boolean,
    focused: boolean
}

export default class Checkbox extends React.Component<CheckboxProps,CheckboxState> {

    static defaultProps = {
        label: '',
    };

    constructor (props: CheckboxProps): void {
        super(props);
        this.checkLabel = React.createRef();
        this.checkInput = React.createRef();
    }

    onChange = (e):void => {
        this.props.onChange(e.target.checked)
    };

    onKeyUp = (event: KeyboardEvent): void => {
        if(event.key === 'Enter') {
            let newValue = !this.checkInput.current.checked;
            this.checkInput.current.checked = newValue;
            this.props.onChange(newValue);
        }
    };

    render = () => {
        return (
            <label tabIndex={0} htmlFor={ 'checkbox__'+this.props.label.toString().replace(' ', '-')}
                onKeyUp={ this.onKeyUp }
                ref={ this.checkLabel }
                className={`checkbox ${this.props.className}`}
            >
                <input id={ 'checkbox__'+this.props.label.toString().replace(' ', '-')}
                       type="checkbox"
                       checked={ this.props.checked }
                       onChange={ this.onChange }
                       onFocus={ this.checkLabel.focus }
                       ref={ this.checkInput }
                />
                <div className="checkbox--box" />
                <div className="checkbox--label">{ this.props.label }</div>
            </label>
        );
    }


}